<template lang="pug">
app-select(
  :options="options"
  :value="value"
  :disabled="disabled"
  text
  hide-arrow
  return-object
  searchable
  slugValue="name"
  slugKey="id"
  placeholder="Assign leader"
  @input="$emit('input', $event)"
)
  .course-leader-icon-placeholder( slot="before")
    app-button(icon icon-large rounded)
      <i class="material-icons">account_circle</i>
    
</template>

<script>
import AppButton from '@/components/global/actions/BaseButton.vue'
import AppSelect from '@/components/global/actions/BaseSelect.vue'

export default {
  props: {
    options: {
      required: true,
      type: Array
    },
    value: {
      required: true
    },
    placeholder: {
      type: String,
      default: 'Assign leader'
    },
    disabled: Boolean
  },
  components: {AppButton, AppSelect}
}
</script>